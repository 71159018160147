export const billingTerms = {
  1: "monthly",
  12: "annual",
  24: "2 year",
  36: "3 year",
};

export const billingTermsAbbr = {
  1: "mo",
  12: "yr",
  24: "2 yrs",
  36: "3 yrs",
};

export const billingTermsSingular = {
  1: "per month",
  12: "per year",
  24: "every 2 years",
  36: "every 3 years",
};

export const termToInteger = {
  monthly: 1,
  annually: 12,
  biennially: 24,
  triennially: 36,
};

export const termList = [1, 12, 24, 36];
