import { NAMES as SURVEY_NAMES, TYPES as SURVEY_TYPES } from "@/utils/surveys";
// import { canCheckout } from "@/utils/useAuth";
import { computed } from "vue";
import constants from "@/utils/constants";
import router from "@/router";
import store from "@/store";

export const getDefaultNickname = () => {
  const pkg = store.getters["packages/site/getItem"];
  const app = store.state.apps.item;
  const surveyType = store.getters["surveys/getSurveyType"];
  if (surveyType === SURVEY_TYPES.STOREBUILDER) {
    return SURVEY_NAMES.STOREBUILDER;
  }
  return `${app?.name} ${pkg?.name} Plan`;
};

export const getCheckoutTitle = () => {
  const pkg = store.getters["packages/site/getItem"];
  const app = store.state.apps.item;
  const requireSurvey = store.getters["surveys/requireSurvey"];
  const surveyType = store.getters["surveys/getSurveyType"];
  if (!app || !pkg) {
    return "";
  }
  return requireSurvey && surveyType === SURVEY_TYPES.STOREBUILDER
    ? SURVEY_NAMES.STOREBUILDER
    : `${app.name} ${pkg.name} Plan`;
};

export const userData = computed(() => ({
  userId: store.state.auth.user?.id,
  clientId: store.state.auth.client?.id,
  firstName: store.state.auth.user?.firstName,
  lastName: store.state.auth.user?.lastName,
  email: store.state.auth.user?.email,
}));

export const cartData = computed(() => {
  const pack = store.getters["packages/site/getItem"];
  const app = store.state.apps.item;
  const coupon = store.getters["cart/getPlanItems"][0]?.discounts[0] || "";
  if (!pack) {
    return {};
  }
  return {
    brand: "Nexcess",
    name: pack.name,
    id: pack.id,
    price: pack.pricing["1"] + pack.appPricing[app.id], // MAD-8283
    quantity: 1,
    coupon,
    category: app?.name,
    affiliation: constants.CHECKOUT.AFFILIATION,
  };
});

const getOrderData = (orderId, revenue, coupon, discount) => ({
  id: orderId,
  affiliation: constants.CHECKOUT.AFFILIATION,
  revenue,
  coupon,
  discount,
});

const getCartData = (cartData, discount) => {
  cartData.discount = discount;
  return cartData;
};

export const analyticsPurchaseComplete = (orderId, revenue, coupon, discount) => {
  store.dispatch("analytics/send", {
    name: "checkoutPurchaseCompleted",
    data: {
      cartData: getCartData(cartData.value, discount),
      userData: userData.value,
      orderData: getOrderData(orderId, revenue, coupon, discount),
    },
  });

  const surveyType = store.getters["surveys/getSurveyType"];
  if (surveyType) {
    store.dispatch("analytics/send", {
      name: "structured",
      data: {
        category: "ecommerce",
        action: `${surveyType.toLowerCase()}_purchased`,
        label: null,
        value: {
          cartData: cartData.value,
          userData: userData.value,
          orderData: getOrderData(orderId, revenue, coupon, discount),
        },
      },
    });
  }
  store.dispatch("cartstack/confirm");
  store.dispatch("analytics/send", {
    name: "navigation",
    data: {
      fullPath: "/thank-you",
      meta: { title: "Checkout Thank You" },
    },
  });
};

export const checkCCError = (msg) => {
  if (
    msg === "Your card was declined. Contact your card issuer, or try again with a different card."
  ) {
    router.push({ name: constants.ROUTES.HOME, query: { paymentProblem: true } }).then(() => {
      store.dispatch("cart/reset");
    });
    return true;
  }
  return false;
};

export const domainItems = computed(() =>
  store.state.cart.items.filter((item) => item.orderType === "domain")
);

// const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);

const billingAddress = computed(() => store.getters["addresses/getBillingAddress"]);

const primaryPaymentMethod = computed(() => store.getters["paymentMethods/getPrimary"]);

export const domainSummary = computed(() => {
  if (!domainItems.value.length) {
    return;
  }
  const addons = domainItems.value.reduce((list, item) => {
    item.package.addons.forEach((addon) => {
      if (!list.find((a) => a.id === addon.id)) {
        list.push(addon);
      }
    });
    return list;
  }, []);
  return `${domainItems.value.length} domain${domainItems.value.length > 1 ? "s" : ""} selected${
    addons.length ? ` + ${addons[0].name}` : ""
  }`;
});

export const paymentSummary = computed(() =>
  primaryPaymentMethod.value?.paymentType === constants.PAYMENT_METHODS.PAYPAL
    ? "PayPal"
    : `${primaryPaymentMethod.value?.type}  ${primaryPaymentMethod.value?.number}`
);

export const domainRegistrationAddress = computed(() => {
  if (domainItems.value.length) {
    return domainItems.value[0].contact;
  }
  return null;
});

export const getNextAuthedStep = (stepMap) => {
  if (!billingAddress.value) {
    return stepMap["billing"];
  } else if (!primaryPaymentMethod.value) {
    return stepMap["payment"];
  } else if (billingAddress.value && primaryPaymentMethod.value) {
    return stepMap["review"];
  }
};

export const setDomainContact = (domainItems, address) => {
  domainItems.forEach((item) => {
    item.contact = { ...address, id: null };
    store.dispatch("cart/updateItem", item);
  });
};

/*
export const initInfo = async () => {
  const billingAddress = computed(() => store.getters["addresses/getBillingAddress"]);
  const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
  const user = computed(() => store.state.auth.user);

  store.dispatch("cart/clearEstimate");
  try {
    await store.dispatch("settings/fetch");
    if (isLoggedIn.value) {
      await store.dispatch("auth/userClientInfo", { reidentify: true, lazy: true });
      // loginComplete.value = true;
    }
  } catch (e) {
    console.log("ERROR", e);
  }

  if (isLoggedIn.value) {
    if (!canCheckout()) {
      store.dispatch("toasts/addToast", {
        variant: "warning",
        message: "Please contact the account owner to complete this purchase.",
      });
      router.push({ name: constants.ROUTES.HOME });
      return;
    }

    store.dispatch("cartstack/setEmail", user.value.email);
    // if user is logged in, load addresses and credit cards
    await Promise.all([
      store.dispatch("addresses/getList"),
      store.dispatch("paymentMethods/getList"),
    ]);

    if (billingAddress.value) {
      techAddressChanged(billingAddress.value);
    }
  }
  store.dispatch("cart/estimate");
};*/
