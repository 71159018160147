import { models } from "leatherman-js";
import store from "@/store";

const apiEventMessageMap = (action, status, name) => {
  if (action === "service:change-term") {
    if (status === "error") return "Something went wrong trying to change your plan terms.";
    else if (status === "pending") return `${name}'s terms will be changed soon.`;
    else if (status === "success") return `You have successfully changed ${name}'s terms!`;
  } else if (action === "service:set-nickname") {
    if (status === "error") return `Something went wrong trying to change ${name}'s name.`;
    else if (status === "pending") return `${name}'s name will be changed soon.`;
    else if (status === "success") return `You have successfully changed ${name}'s name!`;
  } else if (action === "cloud-account:enable-cdn") {
    if (status === "failure") return "CDN could not be enabled.";
    else if (status === "success")
      return "CDN is being enabled. It will be ready in just a moment!";
  } else if (action === "cloud-account:disable-cdn") {
    if (status === "failure") return "CDN could not be disabled.";
    else if (status === "success")
      return "CDN is being disabled. It will be ready in just a moment!";
  }
};

function dispatchApiEventToast(data) {
  const plan = store.getters["plans/get"](data.resource_id);
  const message = apiEventMessageMap(data.action, data.status, plan?.nickname);
  if (message) {
    store.dispatch("toasts/addToast", { variant: "info", message });
  }
}

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
      break;
    case "api-task:update":
      dispatchApiEventToast(data);
      break;
    case "service:add":
      // eslint-disable-next-line no-case-declarations
      const addedPlan = models.Plan.formatLegacy(data);
      if (addedPlan.environmentType !== "production") {
        return;
      }
      store.dispatch("plans/refreshList");
      store.dispatch("plans/refreshAll");
      store.dispatch("toasts/addToast", {
        variant: "info",
        message:
          data.environment_type === "staging"
            ? "Your staging environment will be up and running soon."
            : `${addedPlan.nickname} will be up and running soon.`,
      });
      break;
    case "service:delete":
      // eslint-disable-next-line no-case-declarations
      const deletedPlan = models.Plan.formatLegacy(data);
      store.dispatch("safeHarbor/refreshStagingEnvironments", data.id);
      store.dispatch("safeHarbor/refreshSiteStagingEnvironmentsByServiceId", data.id);
      if (deletedPlan.environmentType !== "production") {
        return;
      }
      store.dispatch("toasts/addToast", {
        variant: "info",
        message:
          data.environment_type === "staging"
            ? "Your staging environment will be removed soon."
            : `${deletedPlan.nickname} has been removed.`,
      });
      store.dispatch("plans/refreshListByPlanId", data.id);
      store.dispatch("plans/refreshAllByPlanId", data.id);
      break;
    case "service:update":
      // eslint-disable-next-line no-case-declarations
      const updatedPlan = models.Plan.formatLegacy(data);
      if (data.environment_type === "staging") {
        store.dispatch("safeHarbor/refreshStagingEnvironments", data.id);
        store.dispatch("safeHarbor/refreshSiteStagingEnvironments", data.id);
        store.dispatch("safeHarbor/refreshSiteStagingEnvironmentsByServiceId", data.id);
        if (data.parent_id) {
          store.dispatch("safeHarbor/refreshStagingEnvironments", data.parent_id);
          store.dispatch("safeHarbor/removeStagingEnvironmentUpdate", data.parent_id);
        }
        if (data?.cloud_account?.reference_account_id) {
          store.dispatch(
            "safeHarbor/refreshSiteStagingEnvironments",
            data?.cloud_account?.reference_account_id
          );
        }
      }
      if (updatedPlan.environmentType !== "production") {
        return;
      }
      store.dispatch("plans/refreshItem", data.id);
      store.dispatch("plans/removeFromUpgradingList", data.service_id);
      store.dispatch("plans/refreshListByPlanId", data.id);
      store.dispatch("plans/refreshAllByPlanId", data.id);
      store.dispatch("auth/clientInfo");
      break;
  }
};

export default handler;
